<template>
  <div>
    <div
      class="block-icon-reglement-fac mr-1"
      @click.prevent.stop="pseudosFiliale(item)"
      title="Pseudo"
    >
      <font-awesome-icon icon="closed-captioning" />
    </div>
    <!--GESTION DES PSEUDOS-->
    <v-dialog
      v-model="modalPseudo"
      persistent
      max-width="1400px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-list"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">GESTION DES PSEUDOS </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalPseudos('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <div v-if="getLoaderPseudoFiliale" class="loading-block">
            <v-progress-circular
              class="ml-2"
              v-if="getLoaderPseudoFiliale"
              indeterminate
              color="#704ad1"
            ></v-progress-circular>
          </div>
          <div v-else class="form-add">
            <v-row>
              <v-col cols="4"></v-col>
              <v-col cols="2">
                <div
                  class="block-icon-table-facture fix-width mr-1"
                  @click.prevent.stop="addPseudoFiliale()"
                  title="Ajouter un exercice"
                >
                  <font-awesome-icon icon="plus" /> Ajouter un pseudo
                </div>
              </v-col>
              <v-col cols="4"> </v-col>
            </v-row>
            <div
              v-for="(pseudo, index) in getListPseudosFiliale"
              :key="index"
              class="card-style"
            >
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    label="Pseudo *"
                    :persistent-placeholder="true"
                    v-model="pseudo.pseudo"
                    outlined
                    :disabled="pseudo.pseudo_alpha"
                    hide-details
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1" class="edit-action">
                  <div
                    class="action-icon validate mr-2"
                    @click="handleSubmitPseudoFiliale(pseudo, index)"
                    v-if="
                      !pseudo.pseudo_alpha &&
                        (pseudo.pseudo != null || pseudo.pseudo != '')
                    "
                  >
                    <font-awesome-icon
                      title="Valider Pseudo"
                      icon="check"
                      class="fa-2x bell1 m-1"
                    />
                  </div>
                  <div
                    class="action-icon cancel mr-2"
                    @click="handleDeletePseudoFiliale(index, pseudo)"
                    v-if="!pseudo.pseudo_alpha"
                  >
                    <font-awesome-icon
                      title="Supprimer Pseudo"
                      icon="times"
                      class="fa-2x bell1 m-2"
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="error-block success-message" v-if="displayModification">
            Les modifications ont été appliquées avec succès.
          </div>
          <div
            v-if="getErrorPseudoFiliale"
            class="error-block message-error-modal"
          >
            <ul v-if="Array.isArray(getErrorPseudoFiliale)" class="mb-0">
              <li v-for="(e, index) in getErrorPseudoFiliale" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>
              {{ getErrorPseudoFiliale }}
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'pseudos',
  props: {
    item: { required: true }
  },
  data() {
    return {
      modalPseudo: false,
      indexCheck: null,
      displayModification: false
    };
  },
  computed: {
    ...mapGetters([
      'getListPseudosFiliale',
      'getLoaderPseudoFiliale',
      'getErrorPseudoFiliale'
    ])
  },
  methods: {
    ...mapActions([
      'getAllPseudoFiliale',
      'updatePseudoFiliale',
      'addNewPseudoFiliale',
      'deletePseudoFiliale',
      'resetPseudoFiliale'
    ]),
    async handleSubmitPseudoFiliale(pseudo, index) {
      this.resetPseudoFiliale();
      if (pseudo.hasOwnProperty('id')) {
        const response = await this.updatePseudoFiliale(pseudo);
        if (response == true) {
          this.displayModification = true;
          setTimeout(() => {
            this.displayModification = false;
          }, 2500);
        }
      } else {
        const response = await this.addNewPseudoFiliale({
          filiale_id: this.item.id,
          pseudo: pseudo.pseudo
        });
        if (response == true) {
          this.getListPseudosFiliale.splice(index, 1);
          this.displayModification = true;
          setTimeout(() => {
            this.displayModification = false;
          }, 1500);
        }
      }
    },
    async handleDeletePseudoFiliale(index, pseudo) {
      if (pseudo.hasOwnProperty('id')) {
        const response = await this.deletePseudoFiliale(pseudo.id);
        if (response == true) {
          this.displayModification = true;
          this.getListPseudosFiliale.splice(index, 1);
          setTimeout(() => {
            this.displayModification = false;
          }, 1500);
        }
      } else {
        this.getListPseudosFiliale.splice(index, 1);
      }
    },
    addPseudoFiliale() {
      this.getListPseudosFiliale.unshift({
        pseudo: null
      });
    },
    pseudosFiliale(item) {
      this.getAllPseudoFiliale(item.id);
      this.handleModalPseudos('show');
    },
    handleModalPseudos(action) {
      if (action == 'show') {
        this.modalPseudo = true;
      } else {
        this.modalPseudo = false;
        this.resetPseudoFiliale();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.error-block {
  margin-left: 35%;
}
.success-message {
  color: green !important;
}
.edit-action {
  padding-top: 0px !important;
  padding-bottom: 10px !important;
  margin-top: 0 !important;
  width: 5%;
  display: flex;
  font-size: 7px;
  height: 80px;
  align-content: center;
  align-items: center;

  .action-icon {
    cursor: pointer;
  }

  .validate {
    color: green;
  }

  .cancel {
    color: #ab2525;
  }
}
.loading-block {
  margin-left: 50%;
  margin-top: 1%;
}
button.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
  color: #fff;
}
.card-style {
  border-radius: 10px;
  border: 1px solid #b5b5b5;
  padding: 28px;
  margin-top: 16px;
  margin-bottom: 20px;
}

.fix-width {
  width: 122px !important;
  padding-bottom: 23px;
  margin-left: 50%;
  padding-left: 8px;
  margin-top: 5%;
}
</style>
